:focus {
    outline: none !important;
}

.text-nowrap {
    white-space: nowrap;
}

:disabled,
.disabled {
    cursor: not-allowed;
}

.disabled * {
    opacity: 0.6;
    cursor: not-allowed !important;

    &:active {
        transform: none !important;
    }
}

a:hover {
    text-decoration: none;
}

.mob-hidden {
    @media (max-width: 768px) {
        display: none !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.clickable {
    cursor: pointer;
    transition: transform 0.25s;

    &:active {
        transform: scale(0.95);
    }
}

.hidden {
    display: none;
}