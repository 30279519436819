.sticky-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1060;
}

.sticky {
    @extend .shadow;

    position: absolute;
    background-color: #fff;
    border-radius: $border-radius;
    border: 1px solid #ddd;
    padding: 15px;

    max-height: 45vh;
    max-width: 40vw;
}

@media(max-width: 768px) {
    .sticky {  
        max-width: 90vw;
    }
}