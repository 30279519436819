.selector-component {
    width: 100%;

    label {
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
            background-color: #eee;
        }
    }
}

.selector-items {
    width: fit-content;

    .badge {
        @extend .clickable;
        padding: 8px;
        font-size: 1em;
        font-weight: normal;

        &.badge-light {
            border: 1px solid #ddd;
        }
    }
}
