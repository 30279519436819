.box {
    display: flex;

    &.box-h {
        flex-direction: row;

        & > .self-end {
            align-self: flex-end;
            margin-left: auto;
        }

        > .box-fill {
            min-width: 0;
        }
    }

    &.box-v {
        flex-direction: column;

        > .box-fill {
            min-height: 0;
        }
    }

    &.box-wrap {
        flex-wrap: wrap;
    }

    &.box-middle {
        align-items: center;
    }

    &.box-center {
        justify-content: center;
    }

    &.box-justified {
        justify-content: space-between;
    }

    &.box-end {
        justify-content: flex-end;
    }

    &.self-stretch {
        align-self: stretch;
    }

    &.box-gap {
        gap: 15px;
    }

    &.box-gap-sm {
        gap: 8px;
    }

    > .box-fill {
        flex: 1 1 auto;
    }
}

.scroll {
    overflow: auto;
}

.scroll-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.scroll-y {
    overflow-x: hidden;
    overflow-y: auto;
}
