$sidebar-bg: darken($primary, 20%);
$sidebar-width: 300px;

.sidebar {
    color: #fff;
    background-color: $sidebar-bg;
    width: 100%;
    max-width: $sidebar-width;
    z-index: 99;

    .sidebar-logo {
        padding: 20px;
    }

    .nav {
        .nav-item-wrapper {
            .nav-item {
                padding: 15px 20px;
                color: inherit;
                border-radius: 9px;
    
                &:hover {
                    background-color: rgba(255,255,255,.2);
                }
    
                &.nav-active {
                    background-color: #eee;
                    color: $sidebar-bg;
                    z-index: 1;
                    box-shadow: 0px 3px 10px -5px rgba(0,0,0,.3);
                }
    
                .svg-inline--fa {
                    font-size: 1.5em;
                }
            }

            > .nav {
                padding: 30px 20px 20px 20px;
                margin-top: -10px;
                background-color: rgba(255,255,255,.5);
                border-radius: 9px;
            }
        }
    }

    > .nav {
        padding: 0 10px;
    }

    @media (max-width: 768px) {
        & {
            position: absolute;
            left: -$sidebar-width;
            top: 63px;
            bottom: 0;
            transition: left 0.5s;
        }

        &.sidebar-open {
            left: 0px;
        }
    }
}

.side-toggle {
    display: none;

    color: #fff;
    background-color: $sidebar-bg;
    height: 100%;
    padding: 0 20px;
    min-width: 63px;

    @media (max-width: 768px) {
        & {
            display: flex;
        }
    }
}
