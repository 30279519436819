@import "variables";

// eslint-disable-next-line
@import "../../node_modules/bootstrap/scss/bootstrap";

@import "layout";
@import "utils";
@import "components";

body,
.app-dock {
  position: relative;
  height: 100vh;
  width: 100%;

  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  margin-top: 0;
}

.btn {
  @extend .clickable;
}
