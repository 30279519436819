.toasts-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    margin: 0 auto;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
}

.toast {
    flex-basis: 0;
    max-width: 600px;
    color: #fff;

    &.toast-info {
        background-color: $secondary;
    }

    &.toast-danger {
        background-color: $danger;
    }
}
