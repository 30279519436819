.table {
  margin-bottom: 0px;

  thead {
    tr td {
      border: 0;
      font-weight: bold;
      background-color: #fff;
      position: sticky;
      top: 0;
      z-index: 10;
    }

    tr:last-child td {
      border-bottom: 1px solid #ccc;
    }
  }

  tbody {
    tr:first-child td {
      border: 0;
    }

    tr td {
      vertical-align: middle;

      > .cell {
        width: 100%;
        max-height: 180px;
        overflow: auto;
        display: block;
      }
    }
  }
}
