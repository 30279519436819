.content {
    padding: 20px;
    position: relative;
    overflow: auto;
    background-color: #f4f4f4;
}

.head {
    background-color: #fff;
    z-index: 999;

    .head-inner {
        padding: 15px 20px;
    }
}

.modal-dialog {
    .modal-content {
        max-height: 90vh;
        max-width: 90vw;

        .modal-header {
            background-color: #f4f4f4;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;

            .close {
                padding: 0.5rem 1rem;
                margin-left: auto;
            }
        }

        .modal-body {
            overflow: auto;
        }
    }
}

.notification-icon {
    position: relative;

    .badge {
        position: absolute;
        top: -8px;
        right: -12px;
    }
}

.badge-icon {
    min-width: 2.1em;
}

.datatable-filters {
    min-width: 200px;
}
