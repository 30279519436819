.counter-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.counter {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  min-width: 300px;

  &.counter-horizontal {
    .counter-inner {
      align-items: center;
    }
  }

  &.counter-vertical {
    .counter-inner {
      flex-direction: column;
    }
  }

  .counter-inner {
    display: flex;
    padding: 0.5em 1em;
  }

  .counter-icon {
    background-color: #555;
    color: #fff;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 4em;
    padding: 1em;
  }

  .counter-value {
    margin-right: 20px;
    font-size: 2em;
  }

  @media (max-width: 768px) {
    font-size: 80%;
  }
}
